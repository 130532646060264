.main {
    text-align: center;
}

.image {
    display: inline;
    margin: auto;
    border: 3px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 20em;
    height: 20em;
    border-radius: 1500px;
    background-color: black;
}