h3 {
    margin: 1em
}

.boxGame {
    text-align: center;
    transition: opacity 0.5s;
}

.gameCanvas {
    margin: 1em 0em 2em 0em;
    border-radius: 0.5em;
    background-color: rgb(204, 202, 202);
    border: 2px solid black;
}

.button {
    border: 2px solid black;
    border-radius: 0.5em;
    background-color: rgb(240, 240, 240);
    color: black;
    padding: 0.5em;
    transition: 0.25s;
    margin: 1.5em;
}

.button:hover {
    color: #aadcff;
}

.gameTitleAndPoints {
    margin: 1em;
}

.mobileControls {
    margin-bottom: 2em;
}

.controlButton {
    width: 6em;
    height: 4em;
    margin: 0.5em 3em;
}

.howToPlay {
    transition: opacity 0.5s;
}

.hidden {
    opacity: 0;
}

.noHeight {
    height: 0;
}