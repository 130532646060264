.main {
    margin: 1em;
    text-align: center;
}

.button {
    border: none;
    background-color: rgb(240, 240, 240);
    color: black;
    transition: 0.25s;
}

.button:hover {
    color: #aadcff;
}