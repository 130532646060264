.app {
    margin: 3rem auto;
    width: 90%;
    max-width: 40rem;
    font-family: Garamond, serif;

}

.box {
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(240, 240, 240);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    margin: 2em 0em 2em 0em;
    transition: height 0.5s;
}

.headerButtons {
    height: 2em;
}

.button {
    margin: 1em;
    border: none;
    background-color: rgb(240, 240, 240);
    color: black;
    transition: 0.25s;
}

.button:hover {
    color: #aadcff;
}

.projectsButton {
    float:left;
}

.howToPlayButton {
    float:right;
}

.boxGameContent {
    margin: 1em;
}

.hidden {
    opacity: 0;
}

.noHeight {
    height: 0;
}