.planner {
    margin: 1em;
}

select {
    margin: 0em 1em;
}

.pokemon {
    display: inline-block;
    text-align: center;
    margin: 1em;
}

.pokemon_img {
    width: 96px;
    height: 96px;
    background-color: #aadcff;
    border-radius: 20em;
}

.pokemon_name {
    position: absolute;
    top: 75%;
}

.pokemonAndName {
    position: relative;
    
}
