.main {
    margin: 1em;
    text-align: center;
}

.name {
    font-size: 2em;
}

.titles {
    font-size: 1em;
}

.navigation {
    display: flex;
    width: 75%;
    margin:auto;
}

.link{
    margin:auto;
    width: 7em;
}

.button {
    border: none;
    background-color: rgb(240, 240, 240);
    color: black;
    transition: 0.25s;
    width: 100%;
    cursor: pointer;
}

.button:hover {
    color: #aadcff;
}